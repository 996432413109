import { useStaticQuery, graphql } from "gatsby"

export const usePuzzleData = () => {
  const { allPuzzleCsv } = useStaticQuery(
    graphql`
        query AllPuzzle {
            allPuzzleCsv(filter: {Image: {}}) {
            group(field: Company_Name) {
                edges {
                node {
                    Company_Name
                    Image
                    Link
                }
                }
            }
            }
        }
    `
  )

  var companys = allPuzzleCsv.group.map(function(e) {
    let companyGroup = e.edges;
    return {
      company: companyGroup[0].node.Company_Name,
      links: companyGroup.map((c) => c.node.Link),
      images: companyGroup.map((c) => c.node.Image),
    };
  });

  return companys;
}