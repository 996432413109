import React from 'react';

import "./slab.scss"

function setOpacity (opacity) {
    document.documentElement.style.setProperty('--nft-opacity', opacity);
}

function Slab({company, links, images}) {

    const toggleMouse = (toggle) => {
        setOpacity(toggle ? 0.3 : 1)
    }

    return (
        <div className={"slab"}
            onMouseEnter={() => toggleMouse(true)}
            onMouseLeave={() => toggleMouse(false)}
            onTouchStart={() => toggleMouse(true)}
            onTouchEnd={() => toggleMouse(false)}
        >
            <h6>{company}</h6>
            <div className="shine">
                <img alt={company+" NFT"} src={images[0]}/>
            </div>
            <div className="links d-flex justify-content-between">
            <a href={links[0]} className="btn" target="_blank" rel="noreferrer">1</a>
            <a href={links[1]} className="btn" target="_blank" rel="noreferrer">2</a>
            <a href={links[2]} className="btn" target="_blank" rel="noreferrer">3</a>
            </div>
        </div>
    )
}

export default Slab