import * as React from 'react'; 

import Seo from "../components/seo"
import Slab from "../components/slab"
import { usePuzzleData } from '../hooks/use-puzzle-data';

import "./nft.scss"

function NFT() {
  const puzzles = usePuzzleData();

  const slabs = puzzles.map((piece) =>
    <Slab
      company={piece.company}
      links={piece.links}
      images={piece.images}
    />
  );

  return (
    <>
      <Seo sharecard="https://vcpuzzle.com/sharecard-nft.png" />
      <div className="nft container d-flex flex-column align-items-center">
        <p>
          A DROP BY <br/> 
          <a href="https://partyround.com" target="_blank" rel="noreferrer">PARTY ROUND</a>
          &nbsp;X&nbsp; 
          <a href="https://www.shrug.vc" target="_blank" rel="noreferrer">SHRUG</a>
        </p>
        <h1>OWn a piece of<br/> tech twitter history</h1>
        <h5>69 vc firms. 3 nfts per firm.</h5>

        <div className="grid d-flex justify-content-evenly flex-wrap">
          {slabs}
        </div>
      </div>
    </>
  ); 
}

export default NFT